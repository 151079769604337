// (1) Define the Flag Types

const headerFooterDefaults = {};

export type HeaderFooterFlagType = Partial<typeof headerFooterDefaults>;

// --------------------------------------------------------------------------

// (2) Define Launch Darkly Flag Keys
// https://app.launchdarkly.com/header-footer/stage/features

export type HeaderFooterFlagKey = keyof HeaderFooterFlagType;

// --------------------------------------------------------------------------

// (3) Linking LD Flag keys to Flag Types

export const headerFooterFlagList: {
  key: HeaderFooterFlagKey;
  value: boolean;
}[] = Object.entries(headerFooterDefaults).map(([key, value]) => ({
  key: key as HeaderFooterFlagKey,
  value: !!value,
}));

// --------------------------------------------------------------------------

// Default flags

export const getDefaultHeaderFooterFlagsByKey = () => {
  return headerFooterDefaults;
};
