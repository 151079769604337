import {
  HeaderFooterFlagKey,
  HeaderFooterFlagType,
  getDefaultHeaderFooterFlagsByKey,
  headerFooterFlagList,
} from '@thrivent/thrivent-public-header-footer';

// --------------------------------------------------------------------------

// (1) Define the Flag Types

const explorationFlagDefaults = {
  'show-live-chat': false,
  'show-virtual-assistant-chat': false,
  'switch-between-new-NBR-Quiz-and-old-NBR-tool': false,
  'show-dedicated-planning-connect-section': false,
  'show-dedicated-planning-faq-section': false,
  'enable-collaborative-planning-process-scroll-events': false,
  'enable-working-with-us-scroll-events': false,
  'enable-inline-video': false,
  'activate-life-insurance': false,
  'activate-disability-income-insurance': false,
  'activate-dedicated-planning-services': false,
  'show-readiness-assessment-quiz': false,
  'dotcom-make-dark': false,
  'dotcom-enable-headless-content': false,
  'dotcom-enable-caching-content': false,
  'update-mutual-fund-links': false,
  'enable-custom-image-optimization': false,
};

export type ExplorationSpecificFlagType = Partial<
  typeof explorationFlagDefaults
>;

export type ExplorationFlagsType = ExplorationSpecificFlagType &
  HeaderFooterFlagType;

// --------------------------------------------------------------------------

// (2) Define Launch Darkly Flag Keys
// https://app.launchdarkly.com/exploration/stage/features

// --------------------------------------------------------------------------

// Linking LD Flag keys to Flag Types

type ExplorationFlagKey = keyof typeof explorationFlagDefaults;
export type ExplorationCombinedFlagKey =
  | ExplorationFlagKey
  // Ignoring because HeaderFooter currently has no flags
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  | HeaderFooterFlagKey;

export const explorationFlagList: {
  key: ExplorationFlagKey;
  value: boolean | string;
}[] = Object.entries(explorationFlagDefaults).map(([key, value]) => ({
  key: key as ExplorationFlagKey,
  value,
}));

export const explorationCombinedFlagList: {
  key: ExplorationCombinedFlagKey;
  value: boolean | string;
}[] = [...headerFooterFlagList, ...explorationFlagList];

// --------------------------------------------------------------------------

// Default flag functions

export const convertFlagKeysToFlagTypes = (
  flagValues: Record<string, boolean | string>,
): ExplorationFlagsType => {
  return explorationCombinedFlagList.reduce(
    (acc, flag) => ({
      ...acc,
      [flag.key]: flagValues[flag.key] ?? flag.value,
    }),
    {},
  );
};

export const getDefaultExplorationFlagsByKey = () => {
  return explorationFlagDefaults;
};

export const getDefaultFlags = () => {
  return {
    ...getDefaultHeaderFooterFlagsByKey(),
    ...getDefaultExplorationFlagsByKey(),
  };
};
